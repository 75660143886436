export const HongHuweather = {
  modules: '01234',
  background: '1',
  tmpColor: 'FFFFFF',
  tmpSize: '16',
  cityColor: 'FFFFFF',
  citySize: '16',
  aqiColor: 'FFFFFF',
  aqiSize: '16',
  weatherIconSize: '24',
  alertIconSize: '18',
  padding: '10px 10px 10px 10px',
  shadow: '0',
  language: 'auto',
  borderRadius: '5',
  fixed: 'false',
  vertical: 'top',
  horizontal: 'left',
  city: 'CN101200806',
  key: '7063308477af4787a17ceacac528eb8c'
}

export const JiaYuweather = {
  modules: '01234',
  background: '1',
  tmpColor: 'FFFFFF',
  tmpSize: '16',
  cityColor: 'FFFFFF',
  citySize: '16',
  aqiColor: 'FFFFFF',
  aqiSize: '16',
  weatherIconSize: '24',
  alertIconSize: '18',
  padding: '10px 10px 10px 10px',
  shadow: '0',
  language: 'auto',
  borderRadius: '5',
  fixed: 'false',
  vertical: 'top',
  horizontal: 'left',
  city: 'CN101200703',
  key: '710158ba2d6345fcbdfbf04380ad3024'
}
export const ChiBiweather = {
  modules: '01234',
  background: '1',
  tmpColor: 'FFFFFF',
  tmpSize: '16',
  cityColor: 'FFFFFF',
  citySize: '16',
  aqiColor: 'FFFFFF',
  aqiSize: '16',
  weatherIconSize: '24',
  alertIconSize: '18',
  padding: '10px 10px 10px 10px',
  shadow: '0',
  language: 'auto',
  borderRadius: '5',
  fixed: 'false',
  vertical: 'top',
  horizontal: 'left',
  city: 'CN101200702',
  key: '893096083ed3446e8d3553bdb0aa5afc'
}
